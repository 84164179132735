/* eslint-disable prettier/prettier */
import { NavLink } from "react-router-dom";
import Button from "../../button";
import React, { useEffect, useState } from 'react';


const MainMenu = () => {
    const baseUrl = global.URL;
    const [language, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem('language');
        return storedLanguage ? storedLanguage : 'id';
      });
      const languageLokal = localStorage.getItem('language');
      const handleChangeLanguage = () => {
        const newLanguage = language === 'id' ? 'en' : 'id';
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        window.location.reload(); // Refresh halaman setelah mengubah bahasa
      };
    
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul style={{display:'flex', alignItems:'center'}}>
                <li>
                    <NavLink
                        className="ditek-menu-text"
                        exact
                        to={baseUrl + ""}
                    >
                        {languageLokal === 'id' ? "Beranda" : "Home"}
                    </NavLink>
                </li>
                <li>
                    <h2 className="text-nav" style={{margin:0}}>{languageLokal === 'id' ? "Profil" : "Profile"}</h2>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "visi-misi"}
                            >
                                {languageLokal === 'id' ? "Tentang Kami" : "About Us"}
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "partner"}
                            >
                                {languageLokal === 'id' ? "Partner" : "Partner"}
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "project"}
                            >
                                 {languageLokal === 'id' ? "Project" : "Project"}
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <h2 className="text-nav" style={{margin:0}}>{languageLokal === 'id' ? "Bisnis" : "Business"}</h2>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "software-development"}
                            >
                                software development
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "it-solution"}
                            >
                                it solution
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "system-integrasi"}
                            >
                                 {languageLokal === 'id' ? "sistem integrasi" : "Integration System"}
                            </NavLink>
                        </li>
                        {/* <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    baseUrl +
                                    "/faq"
                                }
                            >
                                it konsultan
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    baseUrl +
                                    "/blog-right-sidebar"
                                }
                            >
                                iot
                            </NavLink>
                        </li> */}
                    </ul>
                </li>
                <li>
                    <h2 className="text-nav" style={{margin:0}}>{languageLokal === 'id' ? "Tim" : "Team"}</h2>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "direksi"}
                            >
                                {languageLokal === 'id' ? "direksi" : "directors"}
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "marketing-team"}
                            >
                                marketing
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "dev-team"}
                            >
                                developer
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to={baseUrl + "blog"}>{languageLokal === 'id' ? "Artikel" : "Articles"}</NavLink>
                    {/* <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "blog"}
                            >
                                Blog
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    baseUrl +
                                    "/blog-left-sidebar"
                                }
                            >
                                blog grid left sidebar
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    baseUrl +
                                    "/blog-right-sidebar"
                                }
                            >
                                blog grid right sidebar
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "blog-details/1"}
                            >
                                blog details
                            </NavLink>
                        </li>
                    </ul> */}
                </li>
                <li>
                    <NavLink to={baseUrl + "contact"}>{languageLokal === 'id' ? "Kontak" : "Contact"}</NavLink>
                    {/* <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "faq"}
                            >
                                email
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "team"}
                            >
                                maps
                            </NavLink>
                        </li>
                    </ul> */}
                </li>
                <li>
                    <NavLink to={baseUrl + "karir"}>{languageLokal === 'id' ? "Karir" : "Career"}</NavLink>
                    {/* <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "faq"}
                            >
                                marketing staff
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={baseUrl + "team"}
                            >
                                fullstack developer
                            </NavLink>
                        </li>
                    </ul> */}
                </li>
                <li>
                <button
                    style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        // backgroundColor: '#e0e0e0',
                        border: 'none',
                        outline: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={handleChangeLanguage}
                    >
                    {language === 'id' ? (
                        <img
                        src={`${baseUrl}images/logo/indonesia.png`} // Ganti dengan path gambar bendera Indonesia
                        alt="Indonesia"
                        style={{ width: '30px', height: 'auto' }}
                        />
                    ) : (
                        <img
                        src={`${baseUrl}images/logo/english.png`} // Ganti dengan path gambar bendera Inggris
                        alt="English"
                        style={{ width: '30px', height: 'auto' }}
                        />
                    )}
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
