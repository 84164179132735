/* eslint-disable prettier/prettier */
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";

const ContactForm = () => {
    const { register, errors } = useForm({
        mode: "onBlur",
    });
    const baseUrl = global.URL;
    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                action="https://getform.io/f/76bde51e-423f-4aca-bd8b-5ae791f87f0a"
                method="POST"
            >
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Your Name*"
                        ref={register({ required: "Nama wajib diisi" })}
                    />
                    {errors.name && <p style={{color: 'red'}}>{errors.name.message}</p>}
                </div>
                <div className="col-12 col-sm-6 mb-7">
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Your Email*"
                        ref={register({
                            required: "Email wajib diisi",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Alamat email salah",
                            },
                        })}
                    />
                    {errors.email && <p style={{color: 'red'}}>{errors.email.message}</p>}
                </div>

                <div className="col-12 mb-9">
                    <textarea
                        className="form-control massage-control"
                        name="message"
                        id="massage"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                        ref={register({
                            required: "Pesan wajib diisi",
                        })}
                    ></textarea>
                    {errors.message && <p style={{color: 'red'}}>{errors.message.message}</p>}
                </div>
                <div className="col-12">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        Send Message
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
