/* eslint-disable prettier/prettier */
import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import IconBox from "../../../components/icon-box";


const HomeAboutVisiMisiContainer = () => {
    const language = localStorage.getItem('language');
    return (
        <div className="about-us position-relative">
            <div className="container pt-10">
                <div className="row g-0 align-items-center about-center">
                    <div className="container custom-container">
                            {/* <img
                                className="icon-feature"
                                src={`${process.env.PUBLIC_URL}images/feature/icon-collaborate.png`}
                                alt="images_not_found"
                            />
                        <div className="col-xl-10 col-lg-8 mx-auto mb-3 text-center">
                            <SectionTitle
                                classOption="title-section"
                                subTitle=""
                                title="Tentang Kami"
                                excerptClassOption="mb-10"
                                excerpt="Dunia Inovasi Teknologi (DITEK) merupakan perusahaan penyedia layanan, solusi dan produk inovasi teknologi informasi. <br/> DITEK lahir dari kumpulan profesional di bidang IT yang mempunyai pengalaman lebih dari satu dekade dan berkantor pusat di BSD City Tangerang. Seiring perkembangan perusahaan, saat ini DITEK mempunyai layanan dan produk digital yang memiliki berbagai project dan partner yang tersebar di seluruh Indonesia."
                            />
                        </div> */}
                    </div>
                    <div className="row mb-n7" style={ {justifyContent:"space-around"}}>
                        <div className="col-xl-7 col-lg-6 mb-7">
                            <div className="about-content">
                            <img
                                        className="img-about"
                                        src={`${process.env.PUBLIC_URL}images/feature/picture-1.jpg`}
                                        alt="bg-shape"
                                    />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 mb-7">
                            <div className="about-content">
                                <div className="prj-icon">
                                    <img
                                        className="icon-about"
                                        src={`${process.env.PUBLIC_URL}images/feature/svg-visi.png`}
                                        alt="bg-shape"
                                    />
                                    <div className="text-prj">
                                        <h2 style={{fontSize:"20px"}}>{language === 'id' ? "VISI" : "VISION"}</h2>
                                        <span>{language === 'id' ? "Menjadi sebuah perusahaan yang menciptakan masa depan yang lebih baik melalui teknologi dan informasi yang cerdas, terhubung, dan berkelanjutan" : "We strive to become a company that creates a better future through smart, connected, and sustainable technology and information."}</span>
                                    </div>
                                </div>
                                <div className="prj-icon">
                                    <img
                                        className="icon-about"
                                        src={`${process.env.PUBLIC_URL}images/feature/svg-misi.png`}
                                        alt="bg-shape"
                                    />
                                    <div className="text-prj">
                                        <h2 style={{fontSize:"20px"}}>{language === 'id' ? "MISI" : "MISSION"}</h2>
                                    <ul>
                                        <li style={{marginTop: 10}}> <b style={{fontSize: 15, color: "black"}}>{language === 'id' ? "■ Profesional":"■ Professional"}</b> <br/>
                                        {language === 'id' ? "Mengedepankan profesionalisme kemampuan individu dan tim, dalam meng hasilkan produk-produk dan solusi yang cerdas dan tepat guna" : "We prioritize professionalism and the capabilities of individuals and teams in delivering smart and effective products and solutions."}</li>
                                        <li style={{marginTop: 10}}> <b style={{fontSize: 15, color: "black"}}>{language === 'id' ? "■ Transformasi":"■ Transformation"}</b> <br/>
                                        {language === 'id' ? "Solusi transformasi digital yang inovatif untuk meningkatkan efisiensi dan produktifitas kepada mitra bisnis" : "Innovative digital transformation solutions to enhance efficiency and productivity for our business partners."} </li>
                                        <li style={{marginTop: 10}}> <b style={{fontSize: 15, color: "black"}}>{language === 'id' ? "■ Kualitas":"■ Quality"}</b> <br/>
                                        {language === 'id' ? "Memberikan kualitas layanan yang terbaik kepada para klien secara konsisten dan berkelanjutan yang dapat bersaing dalam dunia digital global" : "Consistently providing the best quality services to our clients, enabling them to compete in the global digital world."} </li>
                                    </ul>
                                    
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}images/about/shape.png`}
                alt="bg-shape"
            />
        </div>
    );
};

export default HomeAboutVisiMisiContainer;
