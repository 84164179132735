/* eslint-disable prettier/prettier */
import React from "react";
import PropTypes from "prop-types";

const ContactInfo = ({ data }) => {
    const baseUrl = global.URL;
    return (
        <div className={data.classOption}>
            {/* <h4 className="title">{data.title}</h4> */}
            <img style={{width: "20%", paddingBottom:"10px"}} src={`${process.env.PUBLIC_URL}${data.icon}`} alt="bg-shape" />
            <p dangerouslySetInnerHTML={{ __html: data.info }}></p>
        </div>
    );
};

ContactInfo.propTypes = {
    data: PropTypes.object,
};

export default ContactInfo;
