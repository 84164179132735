/* eslint-disable prettier/prettier */
import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";

const AboutContainer = () => {
    const sceneEl = useRef(null);
    const baseUrl = global.URL;
    const language = localStorage.getItem('language');
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={language === 'id' ? "KAMI BERKOMITMEN DAN PROFESSIONAL" : "WE ARE COMMITTED AND PROFESSIONAL"}
                                title={language === 'id' ? "PERUSAHAAN STARUP <span class='text-primary'>TERBAIK</span><br className='d-none d-xl-block' /> DI INDONESIA SAAT INI" : "THE BEST STARTUP <span class='text-primary'>COMPANY</span><br className='d-none d-xl-block' /> IN INDONESIA CURRENTLY"}
                            />
                            <span className="date-meta">
                            {language === 'id' ? "Sejak 2012" : "Since 2012"} <span className="hr"></span>{language === 'id' ? "Sekarang" : "Now"}
                            </span>
                            <p className="mb-5">
                            {language === 'id' ? "Tim pengembangan kami yang sangat terampil yang berspesialisasi dalam Java, PHP, React, Angular, dan AWS membantu Anda mempercepat bisnis Anda melalui solusi perangkat lunak kustom modern."
                                : "Our highly skilled development team specializing in Java, PHP, React, Angular and AWS helps you accelerate your business through modern custom software solutions." }
                            </p>
                            <p className="high-light mb-8">
                            {language === 'id' ? "5 Tahap kami bekerja yaitu Discover, Document, Development, Deployment, Drive." : "We work in 5 stages, namely Discover, Document, Development, Deployment, Drive." }
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark disabled"
                                text= {language === 'id' ? "Lihat Project kami di bawah ini" : "Check out our Products below"}
                                path="/project"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-lg-first col-md-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${baseUrl}images/about/2.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutContainer;
