/* eslint-disable prettier/prettier */
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Parallax from "parallax-js";

const Testimonial = ({ data }) => {
    const baseUrl = global.URL;
    const dataImage = data.authorThumb.substring(1, data.authorThumb.length);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);

    return (
        <div className="testimonial-slide-item swiper-slide">
            {/* <span className="quote zmdi zmdi-quote"></span>
            <p className="testimonial-text mt-2 mb-5">{data.excerpt}</p> */}
            {/* <div className="avater d-flex">
                <div className="avater-profile">
                    <img
                        src={baseUrl + dataImage}
                        alt="avater"
                    />
                </div>
                <div className="avater-info">
                    <p>{data.name}</p>
                    <span>{data.designation}</span>
                </div>
            </div> */}
            <div className="testimonial-photo scene" id="scene" ref={sceneEl}>
                <div
                // data-depth="0.2"
                >
                    <img
                        src={dataImage}
                        // src={`${process.env.PUBLIC_URL}images/testimonial/project-2.png`}
                        alt="Ditek"
                    />
                </div>
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
};

export default Testimonial;
