/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import FooterCopyright from "../layouts/footer_copyright";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const ContactPage = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={language === 'id' ? "Kontak Kami" : "Contact Us"}
                        excerpt={language === 'id' ? "Anda membutuhkan konsultasi untuk solusi implementasi infrastruktur teknologi informasi? Selamat datang, Anda berada di tempat yang tepat, silahkan kontak Tim Sales & Engineer Kami melalui informasi kontak dibawah ini." : "You need consulting for information technology infrastructure implementation solutions? Welcome, you are in the right place, please contact our Sales & Engineer Team using the contact information below."}
                        image="./images/contact/1.png"
                    />
                    <ContactContainer />
                    <FooterCopyright />

                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ContactPage;
