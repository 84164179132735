/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import IconPartner from "../containers/global/partner";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
// import Footer from "../layouts/footer";
import FooterCopyright from "../layouts/footer_copyright";

import Header from "../layouts/header";
import Layout from "../layouts/index";
import IconItSolution from "../containers/global/it-solution";
import PageBanner from "../containers/global/page-banner";

const ItSolution = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    {/* <Header isShow={true} /> */}
                    <Header />
                    <PageBanner
                        title="IT Solution"
                        excerpt={language === 'id' ? "Dengan adanya IT Solution, operasional perusahaan akan berjalan dengan mudah. Sehingga, Anda dapat lebih berfokus pada pengembangan bisnis. Melalui artikel kali ini, Anda akan memahami apa itu IT Solution dan apa saja layanan bisnis yang disediakannya untuk membantu perkembangan perusahaan Anda." : "With the presence of IT Solutions, your company's operations will run smoothly, allowing you to focus more on business development. In this article, you will understand what IT Solution is and what business services it provides to assist the growth of your company."}
                        image="./images/blog/3.png"
                    />
                    <IconItSolution classOption="section-pb" />
                    <FooterCopyright />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ItSolution;
