/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import IconPartner from "../containers/global/partner";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
// import Footer from "../layouts/footer";
import FooterCopyright from "../layouts/footer_copyright";

import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageBanner from "../containers/global/page-banner";

const OurPartner = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    {/* <Header isShow={true} /> */}
                    <Header />
                    <PageBanner
                        title={language === 'id' ? "Partner Kami" : "Our Partners"}
                        excerpt={language === 'id' ? "Kami bangga bermitra dengan perusahaan-perusahaan terkemuka di bidang teknologi informasi dan iot yang berbagi visi dan misi kami untuk memberikan solusi inovatif dan berkualitas bagi pelanggan kami. Berikut adalah beberapa partner kami yang telah bekerja sama dengan kami dalam berbagai proyek dan layanan." : "We are proud to partner with leading companies in information technology and IoT who share our vision and mission to provide innovative and quality solutions for our customers. Below are some of our partners who have worked with us on various projects and services."}
                        image="./images/blog/1.png"
                    />
                    <IconPartner classOption="section-pb" />
                    <FooterCopyright />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default OurPartner;
