/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import IconPartner from "../containers/global/partner";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
// import Footer from "../layouts/footer";
import FooterCopyright from "../layouts/footer_copyright";

import Header from "../layouts/header";
import Layout from "../layouts/index";
import IconSystemIntegrasi from "../containers/global/system-integrasi";
import PageBanner from "../containers/global/page-banner";

const SystemIntegrasi = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    {/* <Header isShow={true} /> */}
                    <Header />
                    <PageBanner
                        title={language === 'id' ? "Sistem Integrasi" : "Integration System"}
                        excerpt={language === 'id' ? "Perusahaan bidang teknologi informasi dengan fokus pada integrasi sistem infrastruktur jaringan, server, PC, dan software biasa disebut sebagai perusahaan sistem integrator yang bertanggungjawab agar seluruh perangkat yang berbeda-beda tsb dapat bekerja sebagai suatu kesatuan sistem TI." : "Information technology companies with a focus on system integration of network infrastructure, servers, PCs, and software are commonly referred to as system integrator companies that are responsible for all the different devices to work as a unified IT system."}
                        image="./images/blog/1.png"
                    />
                    <IconSystemIntegrasi classOption="section-pb" />
                    <FooterCopyright />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default SystemIntegrasi;
