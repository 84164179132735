/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
/* import HomeAboutContainer from "../containers/home/about"; */
import HomeAboutVisiMisiContainer from "../containers/home/about_visi_misi";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
// import Footer from "../layouts/footer";
import FooterCopyright from "../layouts/footer_copyright";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageBanner from "../containers/global/page-banner";

const VisiMisiPage = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    {/* <Header isShow={true} /> */}
                    <Header />
                    <PageBanner
                        title={language === 'id' ? "Tentang Kami" : "About Us"}
                        excerpt={language === 'id' ? "Dunia Inovasi Teknologi (DITEK) merupakan perusahaan penyedia layanan, solusi dan produk inovasi teknologi informasi. DITEK lahir dari kumpulan profesional di bidang IT yang mempunyai pengalaman lebih dari satu dekade dan berkantor pusat di BSD City Tangerang. Seiring perkembangan perusahaan, saat ini DITEK mempunyai layanan dan produk digital yang memiliki berbagai project dan partner yang tersebar di seluruh Indonesia." : "Dunia Inovasi Teknologi (DITEK) is a company that provides services, solutions, and products in innovative information technology. DITEK was founded by a group of IT professionals with over a decade of experience and is headquartered in BSD City, Tangerang. As the company has grown, DITEK now offers digital services and products with various projects and partners scattered throughout Indonesia."}
                        image="./images/blog/1.png"
                    />
                    <HomeAboutVisiMisiContainer />
                    <FooterCopyright />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default VisiMisiPage;
