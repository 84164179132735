/* eslint-disable prettier/prettier */
import React from "react";
import Iframe from 'react-iframe';

import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";

const ContactContainer = () => {
    const baseUrl = global.URL;
    const language = localStorage.getItem('language');
    const baseMaps = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.8490491279922!2d106.64623012450208!3d-6.318143912684286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e4bbd18d1a2f%3A0xc7311053bebfdd04!2sSEKOLAH%20AZZAKIYAH%20ISLAMIC%20LEADERSHIP!5e0!3m2!1sen!2sid!4v1679135580752!5m2!1sen!2sid";
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-7 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">{language === 'id' ? "Peta Lokasi" : "Location map"}</h3>
                            {/* <p>
                                Top rated construction packages we pleasure
                                rationally encounter
                                <br className="d-none d-xl-block" />
                                consequences interesting who loves or pursue or
                                desires
                            </p> */}
                            <Iframe url={baseMaps}
                                width="100%"
                                height="500px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                                allowFullScreen />
                        </div>

                        {/* <ContactForm /> */}
                    </div>

                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="contact-address text-center">
                            {ContactData &&
                                ContactData.map((single, key) => {
                                    return (
                                        <ContactInfo key={key} data={single} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
