/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import IconDevelopment from "../containers/global/software-development";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
// import Footer from "../layouts/footer";
import FooterCopyright from "../layouts/footer_copyright";

import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageBanner from "../containers/global/page-banner";

const SoftwareDevelopment = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    {/* <Header isShow={true} /> */}
                    <Header />
                    <PageBanner
                        title="Software Development"
                        excerpt={language === 'id' ? "Proses pengembangan perangkat lunak yang dijalankan secara sistematis dan bertujuan untuk menjaga komunikasi antar tim developer dalam mengembangkan sebuah aplikasi. Software development memberikan hasil yang maksimal dan berkualitas dalam pengembangan perangkat lunak." : "The process of software development is carried out systematically and aims to maintain communication among the developer team in developing an application. Software development aims to deliver maximum and high-quality results in software development."}
                        image="./images/blog/2.png"
                    />
                    <IconDevelopment classOption="section-pb" />
                    <FooterCopyright />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default SoftwareDevelopment;
