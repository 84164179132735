/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AccordionContainer from "../containers/accordion";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import FooterCopyright from "../layouts/footer_copyright";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const KarirPage = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={language === 'id' ? "Karir" : "career"}
                        excerpt={language === 'id' ? "Kami mencari individu-individu yang bersemangat, kreatif, dan siap untuk bergabung dengan tim kami untuk membantu kami mencapai tujuan kami yang ambisius. Kami percaya bahwa karyawan kami adalah aset terbesar kami, dan kami berkomitmen untuk memberikan lingkungan kerja yang inklusif dan mendukung untuk memastikan karyawan kami dapat berkembang dan mencapai potensi mereka." : "We are looking for individuals who are passionate, creative and ready to join our team to help us achieve our ambitious goals. We believe that our people are our greatest asset, and we are committed to providing an inclusive and supportive work environment to ensure our people can develop and reach their potential."}
                        image="./images/faq/1.png"
                    />
                    <AccordionContainer />
                    {/* <NewsletterArea /> */}
                    <FooterCopyright />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default KarirPage;
