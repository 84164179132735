/* eslint-disable prettier/prettier */
import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import FooterCopyright from "../layouts/footer_copyright";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const ProjectPage = () => {
    const language = localStorage.getItem('language');
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dunia Inovasi Teknologi" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={language === 'id' ? "Project Kami" : "Our Projects"}
                        excerpt={language === 'id' ? "Kami adalah perusahaan yang bergerak di bidang teknologi informasi dan komunikasi. Kami menyediakan beragam produk keuangan dan bantuan teknis, dan kami membantu negara berbagi dan menerapkan pengetahuan dan solusi inovatif" : "We are a company engaged in the field of Information and Communication Technology. We provide various financial products and technical assistance, and we assist countries in sharing and implementing innovative knowledge and solutions."}
                        image="./images/banner/1.png"
                    />
                    {/* <IconBoxContainer classOption="section-pt" /> */}
                    <AboutContainer />
                    {/* <ServiceListContainer /> */}
                    <TestimonialReverse />
                    {/* <NewsletterArea /> */}
                    <FooterCopyright />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ProjectPage;
