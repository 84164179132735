/* eslint-disable prettier/prettier */
import { useEffect, useRef } from "react";
import SectionTitle from "../../../components/section-title";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonial from "../../../components/testimonial";
import HomeData from "../../../data/home.json";
import SwiperCore, { 
    Navigation, 
    Autoplay,  
    Pagination,
} from "swiper";
import Parallax from "parallax-js";
import Slider from "react-slick";

SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);
SwiperCore.use([Navigation]);
const TestimonialReverse = () => {
    const swiperOption = {
        loop: false,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: ".testimonial-carousel .swiper-button-next",
            prevEl: ".testimonial-carousel .swiper-button-prev",
        },
        pagination: {
            el: ".testimonial-carousel .swiper-pagination",
            clickable: true,
        },
    };

    // const sceneEl = useRef(null);

    // useEffect(() => {
    //     const parallaxInstance = new Parallax(sceneEl.current, {
    //         relativeInput: true,
    //     });

    //     parallaxInstance.enable();

    //     return () => parallaxInstance.disable();
    // }, []);
    const language = localStorage.getItem('language');
    return (
        <section className="testimonial-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Website and Mobile App"
                                title="<span class='text-primary'>Smart Community Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Berfokus pada pragmatik pembangunan kota, komunitas cerdas merupakan pilar spiritual kota cerdas." : "Focusing on the pragmatics of urban development, smart communities are the spiritual pillars of smart cities."} <br/><br/>  {language === 'id' ? "Oleh karena itu, mereka dapat didefinisikan sebagai model dan inisiatif publik dan swasta yang bertujuan menggunakan teknologi untuk mengarahkan dan meningkatkan dampak positifnya terhadap ekonomi, institusi, keberlanjutan dan, yang terpenting, kualitas hidup warga negara. Konsep komunitas cerdas sangat penting untuk meningkatkan rasa memiliki komunitas." : "Therefore, they can be defined as public and private models and initiatives that aim to use technology to direct and enhance its positive impact on the economy, institutions, sustainability and, most importantly, the quality of life of citizens. The concept of smart community is very important to increase the sense of belonging to the community."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10">
                        <div className="testimonial-carousel position-relative">
                            {language === "id" ?
                            <Swiper {...swiperOption} autoplay>
                                {HomeData[7].testimonial &&
                                HomeData[7].testimonial.map(
                                    (single, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <Testimonial
                                                    key={key}
                                                    data={single}
                                                />
                                            </SwiperSlide>
                                        );
                                    }
                                )}
                            </Swiper>
                            :
                            <Swiper {...swiperOption} autoplay>
                            {HomeData[16].testimonial &&
                                HomeData[16].testimonial.map(
                                    (single, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <Testimonial
                                                    key={key}
                                                    data={single}
                                                />
                                            </SwiperSlide>
                                        );
                                    }
                                )}
                        </Swiper>
                            }
                           
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Website and Desktop"
                                title="<span class='text-primary'>HR Platform Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Aplikasi Payroll, HR & Perhitungan PPh 21. HR Project untuk Mengelola Keuangan & Karyawan Perusahaan dengan berbasis web dan desktop, bisa diakses di mana saja. Satu tempat untuk semua data karyawan mulai dari data pribadi, data karir, detil penggajian, rekaman absensi hingga jatah cuti. CV dan dokumen-dokumen karyawan lainnya pun bisa disimpan di sini. HR Project bisa mengingatkan jika kontrak karyawan akan habis. Sistem penggajian karyawan di aplikasi software gaji HR Project yang fleksibel dan dapat diakses online memudahkan perhitungan gaji karyawan dengan beragam komponen tunjangan, termasuk mengkalkulasi lembur, THR, dan bonus." : "Payroll, HR & Income Tax Calculation Applications 21. HR Project to Manage Company Finances & Employees with web and desktop based, can be accessed anywhere. One place for all employee data ranging from personal data, career data, payroll details, attendance records to leave allotments. CVs and other employee documents can also be stored here. HR Project can remind if the employee's contract is about to expire. The employee payroll system in the HR Project salary software application is flexible and can be accessed online, making it easy to calculate employee salaries with various benefits components, including calculating overtime, THR, and bonuses."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10 mt-10">
                        <div className="testimonial-carousel position-relative">
                        {language === 'id' ?  <Swiper {...swiperOption} autoplay>
                                {HomeData[10].project &&
                                    HomeData[10].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> :  <Swiper {...swiperOption} autoplay>
                                {HomeData[20].project &&
                                    HomeData[20].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> }
                           
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Service and Mobile App"
                                title="<span class='text-primary'>Online Trading Platform Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Online Trading Project dilengkapi dengan fitur Trading Signal yang menyediakan referensi jual atau beli produk forex, emas dan komoditi. Online Trading Project atau Trading Signal ini aplikasi trading yang ramah untuk pemula. Trading yang berisikan informasi potensi pergerakan harga yang berasal dari provider analisis pasar global ternama. Performa signal hingga 28 hari terakhir ditampilkan secara transparan. Trading signal dilengkapi level take profit dan stop loss agar Anda dapat membatasi kerugian, bahkan sebelum memulai trading. Trading signal juga akan dikirimkan melalui notifikasi, agar Anda tidak ketinggalan peluang trading online terkini." : "The Online Trading Project is equipped with a Trading Signal feature that provides a reference for selling or buying forex, gold and commodity products. Online Trading Project or Trading Signal is a friendly trading application for beginners. Trading that contains information on potential price movements originating from well-known global market analysis providers. Signal performance up to the last 28 days is displayed transparently. Trading signals are equipped with take profit and stop loss levels so you can limit your losses even before you start trading. Trading signals will also be sent via notifications, so you don't miss the latest online trading opportunities."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10 mt-10">
                        <div className="testimonial-carousel position-relative">
                        {language === 'id' ?  <Swiper {...swiperOption} autoplay>
                                {HomeData[11].project &&
                                    HomeData[11].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> :  <Swiper {...swiperOption} autoplay>
                                {HomeData[22].project &&
                                    HomeData[22].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> }
                           
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Website and Responsive Mobile"
                                title="<span class='text-primary'>Blockchain and NFT Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Binance merupakan salah satu perusahaan aset kripto terbesar di dunia dengan valuasi yang mencapai $4,5 milya dolar AS. Ia juga mengoperasikan bursa pertukaran kripto terpusat (CEX) terbesar yang ada. CEX ciptaan Binance menggunakan cara kerja konvensional bursa pertukaran kripto. Namun, di luar sistem CEX-nya, Binance memiliki berbagai produk cryptocurrency yang beragam. Berinvestasi pada BNB artinya percaya terhadap kekuatan Binance di dunia kripto. Sejak awal 2022, harga BNB relatif kuat jika dibandingkan berbagai altcoin lain yang mengalami penurunan lebih dari 70%." : "Binance is one of the largest crypto asset companies in the world with a valuation of $4.5 billion US dollars. It also operates the largest centralized crypto exchange (CEX) in existence. Binance's CEX uses the conventional way of working of crypto exchanges. However, beyond its CEX system, Binance has a wide variety of cryptocurrency products. Investing in BNB means believing in the power of Binance in the crypto world. Since early 2022, the price of BNB has been relatively strong compared to other altcoins which have seen a decline of more than 70%."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10 mt-4">
                        <div className="testimonial-carousel position-relative">
                        {language === 'id' ? 
                        <Swiper {...swiperOption} autoplay>
                                {HomeData[12].project &&
                                    HomeData[12].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> : 
                            <Swiper {...swiperOption} autoplay>
                                {HomeData[17].project &&
                                    HomeData[17].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> }
                            {/* <Swiper {...swiperOption} autoplay>
                                {HomeData[12].project &&
                                    HomeData[12].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> */}
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Service and Website"
                                title="<span class='text-primary'>Bot Trading Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Robot trading atau dengan istilah Expert Advisor (EA) merupakan sebuah software komputer yang dapat bekerja secara otomatis untuk memonitor pasar, melakukan kalkulasi peluang entry, menempatkan transaksi, dan melakukan manajemen risiko berdasarkan algoritma yang telah ditanamkan pada basis programnya. Sistem ini tidak bisa berjalan sendiri. Sistem ini dikendalikan oleh seseorang dibaliknya. Pengendalinya harus memiliki pengetahuan tentang operasional robot trading, dan instrumen investasi yang disesuaikan dengan kebutuhan pengguna." : "Trading robots or as Expert Advisors (EA) are computer software that can work automatically to monitor the market, calculate entry opportunities, place transactions, and carry out risk management based on algorithms that have been embedded in the program base. This system cannot run alone. This system is controlled by someone behind it. The controller must have knowledge about the operation of trading robots, and investment instruments that are tailored to user needs."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10 mt-5">
                        <div className="testimonial-carousel position-relative">
                        {language === 'id' ? 
                          <Swiper {...swiperOption} autoplay>
                          {HomeData[13].project &&
                              HomeData[13].project.map(
                                  (single, key) => {
                                      return (
                                          <SwiperSlide key={key}>
                                              <Testimonial
                                                  key={key}
                                                  data={single}
                                              />
                                          </SwiperSlide>
                                      );
                                  }
                              )}
                      </Swiper>
                        :
                        <Swiper {...swiperOption} autoplay>
                                {HomeData[18].project &&
                                    HomeData[18].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper>
                        }
                            
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Website and Mobile App"
                                title="<span class='text-primary'>Marketplace Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Saat ini semakin banyak pebisnis yang berjualan melalui e-commerce dan marketplace di Indonesia. Bukan hanya oleh pelaku UMKM saja, bahkan brand–brand besar pun ikut masuk dan berjualan di marketplace secara online. Hal ini terbukti dari angka pertumbuhan marketplace di Indonesia yang terus meningkat secara pesat, khususnya di masa pandemi. Perusahaan marketplace adalah menyediakan platform bertemunya penjual dan pembeli, di mana pembeli bisa melihat produk apa saja yang dijual, lalu perusahaan marketplace mengambil keuntungan melalui komisi dari setiap penjualan." : "Currently, more and more businesses are selling through e-commerce and marketplaces in Indonesia. Not only by MSME actors, even big brands have also entered and sold on the online marketplace. This is evident from the growth rate of the marketplace in Indonesia which continues to increase rapidly, especially during the pandemic. A marketplace company is to provide a platform for sellers and buyers to meet, where buyers can see what products are being sold, then marketplace companies take profits through commissions from each sale."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10 mt-4">
                        <div className="testimonial-carousel position-relative">
                        {language === 'id' ? <Swiper {...swiperOption} autoplay>
                                {HomeData[14].project &&
                                    HomeData[14].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper> : <Swiper {...swiperOption} autoplay>
                                {HomeData[21].project &&
                                    HomeData[21].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper>}
                            
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-10">
                        <div className="testimonial-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="Project Service and Website"
                                title="<span class='text-primary'>CRM Business Project</span>"
                            />
                            <h5 className="project-customer">
                            {language === 'id' ? "Customer relationship management adalah proses di mana bisnis atau organisasi lain mengelola interaksinya dengan pelanggan, biasanya menggunakan analisis data untuk mempelajari sejumlah besar informasi. Berkat perkembangan dunia digital, pencatatan data pelanggan dan cara memperlakukannya sesuai kebutuhan sudah bisa dilakukan aplikasi CRM. Bahkan, sekarang CRM berkaitan dengan strategi bisnis secara keseluruhan. Sistem CRM bertindak sebagai repository yang mengintegrasikan aktivitas penjualan, pemasaran, dan dukungan pelanggan, serta menyederhanakan proses, strategi, dan pengelolaan tenaga penjualan dalam satu sistem." : "Customer relationship management is the process by which a business or other organization manages its interactions with customers, typically using data analysis to learn large amounts of information. Thanks to the development of the digital world, customer data recording and how to treat it according to needs can already be done by CRM applications. In fact, now CRM is related to the overall business strategy. The CRM system acts as a repository that integrates sales, marketing and customer support activities, as well as simplifies the process, strategy and management of the sales force in one system."}
                            </h5>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 mb-10 mt-10">
                        <div className="testimonial-carousel position-relative">
                        {language === 'id' ? 
                        <Swiper {...swiperOption} autoplay>
                        {HomeData[15].project &&
                            HomeData[15].project.map(
                                (single, key) => {
                                    return (
                                        <SwiperSlide key={key}>
                                            <Testimonial
                                                key={key}
                                                data={single}
                                            />
                                        </SwiperSlide>
                                    );
                                }
                            )}
                    </Swiper>
                        :
                        <Swiper {...swiperOption} autoplay>
                                {HomeData[19].project &&
                                    HomeData[19].project.map(
                                        (single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Testimonial
                                                        key={key}
                                                        data={single}
                                                    />
                                                </SwiperSlide>
                                            );
                                        }
                                    )}
                            </Swiper>
                        }
                            
                            <div className="swiper-button-prev">
                                <i className="icofont-rounded-left"></i>
                            </div>
                            <div className="swiper-button-next">
                                <i className="icofont-rounded-right"></i>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialReverse;
